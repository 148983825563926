import {
  Box,
  Button,
  ToggleButton,
  ToggleButtonGroup,
  useTheme,
  Typography,
} from "@mui/material";
import LabeledBox from "../../special/LabeledBox";
import { tokens } from "../../../global/theme/tokens";
import ExoDialog from "../../exo/ExoDialog";
import { useState } from "react";
import useFetch from "../../../api/useFetch";
import { useTranslation } from "react-i18next";
import MoneyLabel from "../../label/MoneyLabel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

const TypeAccessFeatures = ({
  updateValidationOnChange,
  dataCollection,
  field,
  errors,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { t } = useTranslation();

  const [aboSum, setAboSum] = useState(0);
  const [aboDuration, setAboDuration] = useState("annualy");

  const [open, setOpen] = useState(false);

  function handleChange(e) {
    updateValidationOnChange(field.key, e);
  }

  return (
    <>
      <LabeledBox
        label={field.label}
        onClick={() => setOpen(true)}
        className="flex flex-col"
      >
        {aboSum !== 0 && (
          <Box>
            <Box>
              <Typography variant="h4" className="monospace">
                <MoneyLabel money={aboSum} />
              </Typography>
              <Typography variant="h6">{t(aboDuration)}</Typography>
            </Box>
            {dataCollection[field.key] &&
              dataCollection[field.key].map((feature) => (
                <AccessFeatureLabel feature={feature} />
              ))}
          </Box>
        )}
      </LabeledBox>

      <ExoDialog open={open} limitWidth onClose={() => setOpen(false)}>
        <AccessFeatureList
          onChange={handleChange}
          selectedFeatures={dataCollection[field.key]}
          onClose={() => setOpen(false)}
          setAboSum={setAboSum}
          aboSum={aboSum}
          setAboDuration={setAboDuration}
          aboDuration={aboDuration}
        />
      </ExoDialog>
    </>
  );
};

const AccessFeatureList = ({
  onChange,
  selectedFeatures,
  onClose,
  setAboSum,
  aboSum,
  aboDuration,
  setAboDuration,
}) => {
  const { t } = useTranslation();

  const { setApiData } = useFetch("acess-features");

  const aboDurationMap = {
    biannualy: 24,
    annualy: 12,
    monthly: 1,
  };

  const apiData = {
    data: [
      {
        label: "Time tracking",
        key: "time_tracking",
        availableSeats: 0,
        price: {
          biannualy: 3.6,
          annualy: 4.3,
          monthly: 5.4,
        },
      },
      {
        label: "Project access",
        key: "project_access",
        availableSeats: 0,
        price: {
          biannualy: 3.2,
          annualy: 3.8,
          monthly: 4.8,
        },
      },
      {
        label: "Employee requests",
        key: "employee_requests",
        availableSeats: 0,
        price: {
          biannualy: 1.6,
          annualy: 1.9,
          monthly: 2.4,
        },
      },
      {
        label: "Offers and orders",
        key: "offers_and_orders",
        availableSeats: 0,
        price: {
          biannualy: 2.4,
          annualy: 2.9,
          monthly: 3.6,
        },
      },
      {
        label: "Invoices and payment reminder",
        key: "invoices_and_payment_reminder",
        availableSeats: 0,
        price: {
          biannualy: 2.4,
          annualy: 2.9,
          monthly: 3.6,
        },
      },
      {
        label: "Time management",
        key: "time_management",
        availableSeats: 0,
        price: {
          biannualy: 4,
          annualy: 4.8,
          monthly: 6,
        },
      },
      {
        label: "Automatic generated time sheets",
        key: "automatic_generated_time_sheets",
        availableSeats: 0,
        price: {
          biannualy: 1.6,
          annualy: 1.9,
          monthly: 2.4,
        },
      },
      {
        label: "Project management",
        key: "project_management",
        availableSeats: 0,
        price: {
          biannualy: 6.4,
          annualy: 7.7,
          monthly: 9.6,
        },
      },
      {
        label: "Data management",
        key: "data_management",
        availableSeats: 0,
        price: {
          biannualy: 1.6,
          annualy: 1.9,
          monthly: 2.4,
        },
      },
      {
        label: "Product and services list",
        key: "product_and_service_list",
        availableSeats: 0,
        price: {
          biannualy: 1.6,
          annualy: 1.9,
          monthly: 2.4,
        },
      },
      {
        label: "Customers",
        key: "customer",
        availableSeats: 0,
        price: {
          biannualy: 1.6,
          annualy: 1.9,
          monthly: 2.4,
        },
      },
      {
        label: "Ai accounting and receipt management",
        key: "ai_accounting_and_receipt_management",
        availableSeats: 2,
        price: {
          biannualy: 6.4,
          annualy: 7.7,
          monthly: 9.6,
        },
      },
      {
        label: "Employee administration",
        key: "employee_administration",
        availableSeats: 1,
        price: {
          biannualy: 1.6,
          annualy: 1.9,
          monthly: 2.4,
        },
      },
      {
        label: "Employee contract templates",
        key: "employee_contract_templates",
        availableSeats: 0,
        price: {
          biannualy: 0.8,
          annualy: 1,
          monthly: 1.2,
        },
      },
      {
        label: "Employee inquiry center",
        key: "employee_inquiry_center",
        availableSeats: 4,
        price: {
          biannualy: 2.4,
          annualy: 2.9,
          monthly: 3.6,
        },
      },
    ],
  };

  function checkIfSelected(feature) {
    if (!selectedFeatures) return false;
    const index = selectedFeatures.findIndex((obj) => obj.key === feature.key);

    return index !== -1;
  }

  function updateSelectedFeatures(feature) {
    // its the first feature
    if (!selectedFeatures)
      return handleUpdatedSelectedFeatures([feature], aboDuration);
    // its not the first feature
    const index = selectedFeatures.findIndex((obj) => obj.key === feature.key);
    if (index === -1) {
      // add feature
      handleUpdatedSelectedFeatures(
        [...selectedFeatures, feature],
        aboDuration
      );
    } else {
      //remove feature
      const newSelectedFeatures = selectedFeatures.filter(
        (obj) => obj.key !== feature.key
      );
      handleUpdatedSelectedFeatures(newSelectedFeatures, aboDuration);
    }
  }

  function handleUpdatedSelectedFeatures(newSelectedFeatures, duration) {
    let newAboSum = 0;
    newSelectedFeatures.forEach((element) => {
      if (!element.availableSeats) newAboSum += element.price[duration];
    });
    setAboSum(newAboSum);
    onChange(newSelectedFeatures);
  }

  function handleAboDurationChange(e) {
    setAboDuration(e.target.value);
    if (selectedFeatures)
      handleUpdatedSelectedFeatures(selectedFeatures, e.target.value);
  }

  return (
    <Box className="flex flex-col gap-1 h-full overflow-y-auto">
      <Box className="flex justify-between backdrop-blur-lg sticky top-0 z-10 px-2 py-1">
        <Box>
          <Typography variant="h6">{t("Accounting period")}</Typography>
          <ToggleButtonGroup
            size="small"
            value={aboDuration}
            exclusive
            onChange={handleAboDurationChange}
            aria-label="Project Status"
            className="pb-2 backdrop-blur-lg"
          >
            <ToggleButton value="biannualy">{t("Biannualy")}</ToggleButton>
            <ToggleButton value="annualy">{t("Annualy")}</ToggleButton>
            <ToggleButton value="monthly">{t("Monthly")}</ToggleButton>
          </ToggleButtonGroup>
          <Typography variant="h4" className="monospace">
            <MoneyLabel money={aboSum * aboDurationMap[aboDuration]} />
            <Typography variant="h6">
              {t("Invoicing for the period")}
            </Typography>
          </Typography>
        </Box>

        <Box className="flex flex-col gap-2">
          <Typography variant="h2" className="monospace">
            <MoneyLabel money={aboSum} />
            <Typography variant="h6">{t("per month")}</Typography>
          </Typography>
          <Button variant="contained" color="success" onClick={onClose}>
            {t("Continue")}
          </Button>
        </Box>
      </Box>

      {apiData &&
        apiData.data.map((feature) => (
          <AccessFeature
            key={feature.key}
            onClick={updateSelectedFeatures}
            feature={feature}
            selected={checkIfSelected(feature)}
            price={feature.price[aboDuration]}
          />
        ))}
    </Box>
  );
};

const AccessFeature = ({ feature, onClick, selected, price }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const { t } = useTranslation();

  const { label, availableSeats } = feature;

  return (
    <Button
      variant="contained"
      color="secondary"
      onClick={() => onClick(feature)}
      sx={{
        bgcolor: selected ? colors.success + "30" : "",
        borderColor: selected ? colors.success : colors.card,
        borderWidth: "1px",
        borderStyle: "solid",
      }}
    >
      <Box className="flex w-full justify-between">
        <Box className="flex justify-start items-center gap-2">
          {selected ? (
            <CheckCircleIcon color="success" />
          ) : (
            <AddCircleOutlineIcon />
          )}
          <Typography>{t(label)}</Typography>
        </Box>
        <Box className="flex flex-col items-end">
          <Typography className="monospace">
            <MoneyLabel money={Boolean(availableSeats) ? 0 : price} />
          </Typography>
          {Boolean(availableSeats) && (
            <Typography variant="h6">{`${availableSeats} ${t(
              "Available licenses"
            )}`}</Typography>
          )}
        </Box>
      </Box>
    </Button>
  );
};

const AccessFeatureLabel = ({ feature }) => {
  const { t } = useTranslation();
  return (
    <Box className="flex w-full justify-between">
      <Typography>{t(feature.label)}</Typography>
    </Box>
  );
};

export default TypeAccessFeatures;
