import {
  Box,
  useTheme,
  Typography,
  Breadcrumbs,
  IconButton,
  Link,
} from "@mui/material";
import { Toolbar } from "./project/Toolbar";
import { useTranslation } from "react-i18next";
import SettingsApplicationsIcon from "@mui/icons-material/SettingsApplications";
import useFetch from "../../api/useFetch";
import { ProjectPhaseStack } from "./project/phase/ProjectPhaseStack";
import Header from "../special/Header";
import { useState } from "react";
import ExoDialog from "./ExoDialog";
import NewEmptyProject from "../new/NewEmptyProject";
import { NavLink, useNavigate } from "react-router-dom";
import { hasPermissions } from "../../auth/hasPermissions";
import BarChartIcon from "@mui/icons-material/BarChart";
import { ProjectUserStack } from "./project/ProjectUserStack";
import { tokens } from "../../global/theme/tokens";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import ExoAvatar from "./ExoAvatar";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import EmailIcon from "@mui/icons-material/Email";

export const ExoProject = ({ projectId = 18, allowEditMode }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  const { t } = useTranslation();
  const navigate = useNavigate();

  // project
  const { apiData, setApiData } = useFetch(`projects/${projectId}`);
  const [open, setOpen] = useState(false);

  function handleUpdate(data) {
    setApiData({ ...apiData, data: data });
  }

  // project management
  const [inEditMode, setInEditMode] = useState(false);
  const toollist = [
    {
      Icon: SettingsApplicationsIcon,
      label: t("Settings"),
      onClick: () => setOpen(true),
    },
    {
      Icon: BarChartIcon,
      label: t("Analytics"),
      onClick: () => navigate(`/projects/${projectId}`),
      hidden: !hasPermissions("projects-statistics"),
    },
  ];

  const supervisor = apiData && apiData.data.supervisor;

  return (
    <Box className="h-full w-full flex flex-col mx-auto">
      <Breadcrumbs aria-label="breadcrumb">
        <NavLink
          to={"/projects"}
          underline="hover"
          className="flex items-center"
          color="inherit"
        >
          <AccountTreeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
          {t("Projects")}
        </NavLink>
        <Typography
          sx={{ color: "text.primary" }}
          className="flex items-center"
        >
          {apiData && apiData.data.image && (
            <img
              className=" h-5 w-5 object-cover mr-2 rounded-full"
              src={apiData.data.image.temporaryUrl}
            />
          )}
          {apiData && apiData.data.name}
        </Typography>
      </Breadcrumbs>
      <Header title={apiData && apiData.data.name} />

      {apiData && apiData.data.editable && (
        <Toolbar
          tools={toollist}
          setInEditMode={setInEditMode}
          inEditMode={inEditMode}
        />
      )}

      {apiData && (
        <ProjectPhaseStack project={apiData.data} editmode={inEditMode} />
      )}
      {apiData && (
        <Box
          className=" p-4 rounded-lg mt-4 backdrop-blur-lg"
          sx={{ bgcolor: colors.glass }}
        >
          <Box>
            <Typography variant="h6" className="pb-2">
              {t("Supervisor")}
            </Typography>
            <Box className="flex gap-4">
              <ExoAvatar picture={supervisor.picture.temporaryUrl} />
              <Box className="flex flex-col">
                <Typography variant="h4">{`${supervisor.salutation} ${supervisor.firstName} ${supervisor.lastName}`}</Typography>

                <Box className="flex items-center gap-1">
                  <Link href={`tel:${supervisor.phone}`}>
                    <IconButton size="small">
                      <PhoneIphoneIcon />
                    </IconButton>
                  </Link>
                  <Typography>{supervisor.phone}</Typography>
                </Box>

                <Box className="flex items-center gap-1">
                  <Link href={`mailto:${supervisor.email}`}>
                    <IconButton size="small">
                      <EmailIcon />
                    </IconButton>
                  </Link>
                  <Typography>{supervisor.email}</Typography>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box>
            <Typography variant="h6" className="pb-2">
              {t("Team")}
            </Typography>
            <Box className="flex">
              <ProjectUserStack
                noLabel
                displayCount={8}
                editmode={apiData.data.editable}
                users={apiData.data.teamMembers}
                api={"users"}
                changeApi={"project/" + apiData.data.id + "/projectassignment"}
                onChange={(res, newMembers) =>
                  setApiData({
                    ...apiData,
                    data: { ...apiData.data, teamMembers: newMembers },
                  })
                }
              />
            </Box>
          </Box>
        </Box>
      )}
      <ExoDialog open={open}>
        <NewEmptyProject
          onClose={() => setOpen(false)}
          onCreate={handleUpdate}
          startDataCollection={apiData && apiData.data}
        />
      </ExoDialog>
    </Box>
  );
};
