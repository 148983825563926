import { Box, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import * as yup from "yup";
import Alert from "@mui/material/Alert";
import Cookies from "universal-cookie";
import { API_POST_NO_TOKEN, DBRequest, useConfig } from "../../api/api";
import Loader from "../../components/special/Loader";
import { tokens } from "../../global/theme/tokens";
import ExoForm from "../../components/exo/ExoForm";
import { useTranslation } from "react-i18next";

const checkoutSchema = yup.object().shape({
  password: yup.string().required("required"),
  email: yup.string().email("invalid email").required("required"),
});

const Login = () => {
  const cookies = new Cookies();
  const current = new Date();
  const nextYear = new Date();
  nextYear.setFullYear(current.getFullYear() + 1);

  const [errMsg, setErrMsg] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const colorMode = cookies.get("colorMode");

  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  const { t } = useTranslation();
  const config = useConfig();

  const handleFormSubmit = (data) => {
    DBRequest({
      config,
      customPath: true,
      path: config.API_LOGIN,
      method: API_POST_NO_TOKEN(data),
      onResponse: (e) => handleSetCookies(e.data),
      onError: handleLoginFailed,
      onLoading: setIsLoading,
    });
  };

  function handleLoginFailed(error, status) {
    setErrMsg(`${error.message}`);
  }

  function handleSetCookies(data) {
    setErrMsg("");
    try {
      cookies.set("accessToken", data.token, { path: "/", expires: nextYear });
      cookies.set("userId", data.user.id, { path: "/", expires: nextYear });
      cookies.set("firstName", data.user.firstName, {
        path: "/",
        expires: nextYear,
      });
      cookies.set("lastName", data.user.lastName, {
        path: "/",
        expires: nextYear,
      });
      if (data.user.picture) {
        cookies.set("profilePicture", data.user.picture.temporaryUrl, {
          path: "/",
          expires: nextYear,
        });
      }
      if (data.user) {
        cookies.set("payType", data.user.payType, {
          path: "/",
          expires: nextYear,
        });
        cookies.set("hoursPerDay", data.user.hoursPerDay, {
          path: "/",
          expires: nextYear,
        });
      }
      // preferences
      cookies.set(
        "colorTheme",
        data.user.preferences.colorTheme
          ? data.user.preferences.colorTheme
          : "EMPTY",
        { path: "/", expires: nextYear }
      );
      cookies.set("colorMode", data.user.preferences.colorMode, {
        path: "/",
        expires: nextYear,
      });
      if (data.user.preferences.backgroundImageDark) {
        cookies.set(
          "backgroundImageDark",
          data.user.preferences.backgroundImageDark.temporaryUrl,
          { path: "/", expires: nextYear }
        );
      }
      if (data.user.preferences.backgroundImageLight) {
        cookies.set(
          "backgroundImageLight",
          data.user.preferences.backgroundImageLight.temporaryUrl,
          { path: "/", expires: nextYear }
        );
      }
      if (data.initialSetup) {
        cookies.set("initialSetup", true, { path: "/", expires: nextYear });
      }
      if (data.search) {
        cookies.set("searchToken", data.search.token, {
          path: "/",
          expires: nextYear,
        });
        cookies.set("searchIndexes", data.search.allowedIndexes, {
          path: "/",
          expires: nextYear,
        });
        cookies.set("searchPrefix", data.search.prefix, {
          path: "/",
          expires: nextYear,
        });
      }
      // permissions
      if (data.permissions) {
        cookies.set("permissions", data.permissions, {
          path: "/",
          expires: nextYear,
        });
      } else {
        cookies.set(
          "permissions",
          { admin: "*" },
          { path: "/", expires: nextYear }
        );
      }
      if (cookies.get("accessToken")) {
        window.location.replace("/");
      }
    } catch (err) {
      window.alert("ERROR: setting cookies");
    }
  }

  useEffect(() => {
    // redirect if token isset
    const currentCookies = cookies.getAll();
    if (currentCookies.accessToken) {
      window.location.replace("/");
    }
  }, []);
  const fields = [
    {
      label: t("E-Mail"),
      key: "email",
      type: "text",
    },
    {
      label: t("Password"),
      key: "password",
      type: "password",
    },
  ];

  return (
    <Box className="h-full flex justify-center items-center relative">
      <img
        src={
          colorMode === "dark"
            ? "/assets/uploads/background-dark.webp"
            : "/assets/uploads/background-bright.webp"
        }
        style={{
          width: "100%",
          height: "100%",
          objectFit: "cover",
          position: "absolute",
          top: "0",
          left: "0",
          objectPosition: "32%",
        }}
      />
      <Box
        sx={{ bgcolor: colors.glass }}
        className=" backdrop-blur-lg rounded-3xl w-80 p-4 md:w-90 flex flex-col gap-5 justify-center"
      >
        <img
          src={
            colorMode === "dark"
              ? "../../assets/svg/exopaper-text-darkmode.svg"
              : "../../assets/svg/exopaper-text.svg"
          }
          className="w-60 h-16 m-auto "
        />
        {errMsg != "" && <Alert severity="error">{errMsg}</Alert>}
        <ExoForm
          validationSchema={checkoutSchema}
          fields={fields}
          submitText={t("Login")}
          sx={{
            button: {
              width: "100%",
              backgroundColor: colors.bgInput,
              fontSize: "15px",
            },
          }}
          onSubmit={handleFormSubmit}
        />
      </Box>
      <Loader active={isLoading} type="modern" />
    </Box>
  );
};

export default Login;
